import { useEffect } from 'react';
import styled from 'styled-components';
import { invert } from 'lodash';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { useLayoutContentContext } from '@home-diy-toolbox/web/contentful';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import {
	BatteryFilterPathNames,
	BatteryPageIds,
	CheckupQuestionPathnames,
	Checkups,
	GenericPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { Step, useBreadcrumb } from '../../../providers';
import { ArticleGridSection } from '../../../components/ArticleGridSection';
import { ExpertContactCard, OtherServices } from '../../../components';
import { BatteryReplacementEligibilityCard } from './BatteryReplacementEligibilityCard';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 0 1rem;
	gap: 2.25rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		padding: 0 1.5rem;
	}

	${breakpoints.xxl} {
		padding: 0 1rem;
		max-width: 90rem;
	}
`;

const BatteryLandingPage = (): JSX.Element => {
	const {
		batteryPage: {
			pageTitle,
			articleGridSection,
			batteryReplacementEligibilityCard,
			serviceListTitle,
		},
	} = useLayoutContentContext();

	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();
	useEffect(() => {
		if (pageTitle) {
			const breadcrumbSteps: Step[] = [
				{
					path: '/',
					label: 'Device Care',
				},
				{
					path: CheckupQuestionPathnames.BATTERY,
					label: 'batteryHeader',
				},
				{
					label: pageTitle,
				},
			];
			setBreadcrumbSteps(breadcrumbSteps);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageTitle]);
	return (
		<Container className="widecontainer">
			<ArticleGridSection articleGridSection={articleGridSection} />
			<BatteryReplacementEligibilityCard
				batteryReplacementEligibilityCard={batteryReplacementEligibilityCard}
			/>
			<ExpertContactCard from={GenericPageIds.BATTERY_PAGE} />
			<OtherServices
				title={serviceListTitle}
				checkups={[
					Checkups.SIGNAL,
					Checkups.SPEED,
					Checkups.DEVICE_PERFORMANCE,
					Checkups.DEVICE_PERFORMANCE_YEAR_2,
				]}
			/>
		</Container>
	);
};

const pathname = window.location.pathname;
const subpath = pathname.match(/\/device-care(\/.+)/);
const filter = subpath && subpath.length > 1 ? subpath[1] : '/battery-popular';
const signalPathMap = invert(BatteryFilterPathNames);
const page = signalPathMap[filter];

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: BatteryPageIds[page],
	CheckupType: Checkups.BATTERY,
})(BatteryLandingPage);
