import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
	CheckupService,
	CheckupServiceLocation,
	useLayoutContentContext,
} from '@home-diy-toolbox/web/contentful';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { ChatPromptsCTA, HeroSection } from '../../components';
import { ServiceSection } from '../../components/CheckupServiceSection';
import { useFlow } from '../../providers';
import { useHeader } from '../../features/header';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import { ArticleGridSection } from '../../components/ArticleGridSection';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
`;

const OnlineServiceContainer = styled.section`
	margin-top: 1.5rem;
`;

const ArticleGridContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 3.75rem;
	}
`;

const InStoreServiceContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 3.6rem;
	}

	&:empty {
		margin-top: 0;
	}
`;

const ChatPromptContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 4.4375rem;
	}
`;

const filterSvcs = (svcs: Array<CheckupService>, location: CheckupServiceLocation) => {
	return svcs.filter(({ location: serviceability }: CheckupService) =>
		serviceability.includes(location)
	);
};

export const NewHomePage = () => {
	const { t } = useTranslation();
	const { checkupServices, homePage } = useLayoutContentContext();

	const remoteServices: CheckupService[] = filterSvcs(
		checkupServices ?? [],
		CheckupServiceLocation.REMOTE
	);
	const inStoreServices: CheckupService[] = filterSvcs(
		checkupServices ?? [],
		CheckupServiceLocation.IN_STORE
	);

	const {
		flow: { sessionData },
		setCurrentQuestionIndex,
	} = useFlow();
	const { updateHeader } = useHeader();

	useEffect(() => {
		updateHeader(
			{
				title: t('Device Care'),
			},
			undefined,
			() => setCurrentQuestionIndex(sessionData.currentQuestionIndex - 1)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionData.currentQuestionIndex, setCurrentQuestionIndex, t]);

	const optimizeYourDeviceTitle = t('HomePage.RemoteCheckupServices.Section.Title');
	const inStoreServicesTitle = t('HomePage.InStoreCheckupServices.Section.Title');

	return (
		<Container className="widecontainer">
			<HeroSection />
			<OnlineServiceContainer>
				<ServiceSection
					sectionTitle={optimizeYourDeviceTitle}
					services={remoteServices}
				/>
			</OnlineServiceContainer>
			<ArticleGridContainer>
				<ArticleGridSection
					deviceOS={sessionData.deviceOS}
					articleGridSection={homePage.guidelines}
				/>
			</ArticleGridContainer>
			<InStoreServiceContainer>
				<ServiceSection sectionTitle={inStoreServicesTitle} services={inStoreServices} />
			</InStoreServiceContainer>
			<ChatPromptContainer>
				<ChatPromptsCTA />
			</ChatPromptContainer>
		</Container>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: GenericPageIds.NEW_HOME_PAGE,
	CheckupType: undefined,
})(NewHomePage);
