import styled from 'styled-components';
import {
	breakpoints,
	colors,
	ManualSelectionChips,
	Text,
} from '@soluto-private/mx-asurion-ui-react';
import ExpertChatImg from './assets/ExpertChat.svg';
import HomeTechImg from './assets/HomeTechRelaxation.svg';
import { useLayoutContentContext } from '@home-diy-toolbox/web/contentful';
import { ComponentIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { getChatClient } from '../../features/chat/components';

export const Background = styled.div`
	position: absolute;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		z-index: -1;
		top: 2rem;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: ${colors.neutralBrightest};
		box-shadow: 0 0 0 100vmax ${colors.neutralBrightest};
		clip-path: inset(0 -100vmax);
	}

	${breakpoints.lg} {
		top: 2.6875rem;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

export const HomeTechContainer = styled.div`
	display: none;

	${breakpoints.lg} {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
	}
`;

export const HomeTech = styled.img`
	width: 100%;
	max-width: 30.5894rem;
	margin-top: 2.6875rem;
`;

export const ExpertSetupContainer = styled.div`
	width: 100%;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	${breakpoints.lg} {
		width: 50%;
	}
`;

export const ExpertSetup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding-top: 3.0731rem;
	border-radius: 0.3125rem;
	color: ${colors.black};
	background-color: ${colors.white};
	gap: 1.375rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		max-width: 22.3rem;
		padding: 2.0731rem 2rem 2.375rem 2rem;
		border: none;
		box-shadow: 0 0.25rem 1.875rem rgba(0, 0, 0, 0.2);
		margin-bottom: 2rem;
	}

	${breakpoints.lg} {
		max-width: 24.5rem;
		padding: 4.0731rem 3.125rem 4.375rem 3.125rem;
		margin-bottom: 4rem;
	}
`;

export const ExpertChat = styled.img`
	width: 100%;
	max-width: 13.75rem;
`;

const TitleContainer = styled.div`
	max-width: 24.375rem;
	text-align: center;
`;

export const ButtonContainer = styled.div`
	max-width: 24.375rem;
`;

export const NoWrapText = styled.span`
	white-space: nowrap;
`;

export const ChatPromptsCTA = () => {
	const { dispatchEvent } = useDispatchEvent();
	const { homePage } = useLayoutContentContext();
	const { greeting1, greeting2, chatPrompts } = homePage.chatPromptCta;

	const chatButtons = chatPrompts.map((chat) => ({
		label: chat.buttonText,
		onClick: async () => {
			getChatClient().openMessagingOverlay(chat.chatText);

			dispatchEvent(AnalyticEventType.CLICK, {
				ActionId: `${ComponentIds.CHAT_PROMPT_BUTTON}`,
				ButtonText: chat.buttonText,
			});
		},
	}));

	return (
		<Container data-pw="chatPromptsCta">
			<HomeTechContainer>
				<HomeTech src={HomeTechImg} alt="Home Tech Relaxation" />
			</HomeTechContainer>
			<ExpertSetupContainer>
				<ExpertSetup>
					<ExpertChat src={ExpertChatImg} alt="Expert Chat" />
					<TitleContainer>
						<Text size={3}>{greeting1}</Text> <br />
						<Text size={3} weight="heavy">
							{greeting2}
						</Text>
					</TitleContainer>
					<ButtonContainer>
						<ManualSelectionChips
							chips={chatButtons}
							layout="wrap-center"
							size="medium"
						/>
					</ButtonContainer>
				</ExpertSetup>
			</ExpertSetupContainer>
			<Background />
		</Container>
	);
};
