import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	AnalyticViewType,
	useAnalytics,
	withAnalyticOnView,
	withExtras,
	AnalyticEventType,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	useNetworkTestContext,
	WebScanState,
	formatSpeed,
} from '@home-diy-toolbox/web/network-test';
import styled from 'styled-components';
import {
	CheckupDiagnosticsPathnames,
	ComponentIds,
	DiagnosticsPageIds,
	DiagnosticsCategoryType,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../../../../providers';
import { CheckupContainer, QuestionHeader, NextButton } from '../../../../components';
import { UploadDownloadTest } from './UploadDownloadTest';
import { useTranslation } from 'react-i18next';

const TitleWrapper = styled(QuestionHeader)`
	text-align: center;
	margin-top: 60px;
`;

const CancelButton = styled(NextButton)``;

const SignalUploadDownloadPage = () => {
	const pageId = DiagnosticsPageIds.SIGNAL_UPLOAD_DOWNLOAD;
	const cancelTestButtonId = `${pageId}_${ComponentIds.CANCEL_TEST_BUTTON}`;

	const { t } = useTranslation();

	const history = useHistory();
	const analytics = useAnalytics();
	const { updateDiagnostics } = useFlow();
	const { usePageContent } = useCheckupContentContext();

	const { cancelSpeedTest, webScanState, startSpeedTest, downloadSpeed } =
		useNetworkTestContext();

	const { questionTitleCopy, nextButtonCopy: cancelButtonCopy } = usePageContent(pageId);

	const [loading, setLoading] = useState(true);

	const onTestComplete = () => {
		analytics.dispatcher
			.extend(
				withExtras({
					DownloadSpeed: formatSpeed(downloadSpeed),
					CheckupType: Checkups.SIGNAL,
				})
			)
			.dispatch(AnalyticEventType.SIGNAL_TEST_COMPLETE);
		history.push(CheckupDiagnosticsPathnames.SIGNAL_TEST_COMPLETE, {
			from: history.location.pathname,
		});
	};

	const onCancelSignalTest = () => {
		cancelSpeedTest();
		history.replace(CheckupDiagnosticsPathnames.SIGNAL_TURN_OFF_WIFI, {
			from: history.location.pathname,
		});
	};

	useEffect(() => {
		setLoading(false);
		if (webScanState === WebScanState.FINISHED) {
			onCancelSignalTest();
		} else {
			startSpeedTest();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!loading && webScanState === WebScanState.FINISHED) {
			updateDiagnostics(
				DiagnosticsCategoryType.SIGNAL_DIAGNOSTICS,
				'downloadSpeed',
				formatSpeed(downloadSpeed)
			);

			setTimeout(onTestComplete, 1000);
		}
	}, [webScanState]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<CheckupContainer>
			<TitleWrapper>{questionTitleCopy}</TitleWrapper>
			<UploadDownloadTest />
			<CancelButton
				data-test-cy={`speedTest_${ComponentIds.CANCEL_TEST_BUTTON}`}
				onClick={onCancelSignalTest}
				disabled={webScanState === WebScanState.FINISHED}
				aria-label={t('cancelSpeedTestButtonName')}
				actionId={cancelTestButtonId}
				data-cy={cancelTestButtonId}
				analyticExtras={{
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{cancelButtonCopy}
			</CancelButton>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.SIGNAL_UPLOAD_DOWNLOAD,
	CheckupType: Checkups.SIGNAL,
})(SignalUploadDownloadPage);
