import styled from 'styled-components';
import { colors, Text } from '@soluto-private/mx-asurion-ui-react';
import { ServiceList, ListProps } from '../../../components/ServiceCards';

interface Props extends ListProps {
	title: string;
	description: string;
	visual: string;
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 1em 0;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
`;

const Image = styled.img`
	max-width: 120px;
	max-height: 120px;
`;

export const Section = ({ title, description, visual, services }: Props): JSX.Element => {
	return services.length > 0 ? (
		<>
			<Container>
				<Header>
					<Text size={3} weight="heavy" role="heading" as="h2">
						{title}
					</Text>
					<Text size={1} color={colors.neutralDeeper}>
						{description}
					</Text>
				</Header>
				{/*TODO: update alt tag*/}
				<Image src={visual} alt="Icon" />
			</Container>
			{<ServiceList services={services} />}
		</>
	) : null;
};
