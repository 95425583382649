import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { union } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	CheckupCategoryType,
	CheckupQuestionPathnames,
	CheckupPageIds,
	ComponentIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import { removeArrayFromArray } from '@home-diy-toolbox/web/utils';
import {
	AnswerSelectionInstructionsWrapper,
	CheckupContainer,
	CheckboxWithLabel,
	CheckupQuestionsProgress,
	NextButton,
	QuestionHeader,
} from '../../../../components';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 48px;
`;

const SignalIssuesPage: FunctionComponent = () => {
	const pageId = CheckupPageIds.SIGNAL_ISSUES;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();
	const { updateCheckupPage, getCheckupForPageId } = useFlow();
	const { usePageContent } = useCheckupContentContext();

	const { questionTitleCopy, instructionsCopy, answerOptions, nextButtonCopy } =
		usePageContent(pageId);

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.SIGNAL_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedSignalIssuesAnswer, setSelectedSignalIssuesAnswer] =
		useState<string[]>(latestAnswers);
	const [selectedSignalIssuesTags, setSelectedSignalIssuesTags] =
		useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		const answerAlreadyExistsIndex = selectedSignalIssuesAnswer.indexOf(answerSelected);
		const indexOfNone = selectedSignalIssuesAnswer.indexOf('none');

		if (answerAlreadyExistsIndex > -1) {
			selectedSignalIssuesAnswer.splice(answerAlreadyExistsIndex, 1);
			setSelectedSignalIssuesAnswer([...selectedSignalIssuesAnswer]);

			setSelectedSignalIssuesTags(removeArrayFromArray(selectedSignalIssuesTags, tags));
		} else {
			if (answerSelected === 'none') {
				setSelectedSignalIssuesAnswer([answerSelected]);
				setSelectedSignalIssuesTags(tags);
			} else {
				const updatedState =
					indexOfNone > -1
						? [answerSelected]
						: [...selectedSignalIssuesAnswer, answerSelected];
				setSelectedSignalIssuesAnswer(updatedState);

				const updatedTags =
					indexOfNone > -1 ? tags : union(selectedSignalIssuesTags, tags);
				setSelectedSignalIssuesTags(updatedTags);
			}
		}
	};

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.SIGNAL_CHECKUP,
			pageId,
			selectedSignalIssuesAnswer,
			selectedSignalIssuesTags
		);
		history.push(CheckupQuestionPathnames.SIGNAL_ISSUE_PLACES, {
			from: history.location.pathname,
		});
	};

	const renderAnswerOptions = () =>
		answerOptions.map(({ fields: { id: answerId, copy, tags } }) => {
			const buttonId = `${pageId}_${answerId}`;
			return (
				<CheckboxWithLabel
					value={answerId}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={selectedSignalIssuesAnswer.includes(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		});

	const isNextButtonDisabled = () => selectedSignalIssuesAnswer.length === 0;

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress currQuestion={1} checkupType={Checkups.SIGNAL} />
			<QuestionHeader>{questionTitleCopy}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={instructionsCopy} />
			</AnswerSelectionInstructionsWrapper>
			{renderAnswerOptions()}
			<NextButtonWrapper
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={isNextButtonDisabled()}
				onClick={onNextButtonClick}
				analyticExtras={{
					SelectedAnswers: selectedSignalIssuesAnswer,
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.SIGNAL_ISSUES,
	CheckupType: Checkups.SIGNAL,
})(SignalIssuesPage);
