import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	CheckupQuestionPathnames,
	CheckupDiagnosticsPathnames,
	ComponentIds,
	DiagnosticsPageIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import {
	CheckupContainer,
	QuestionHeader,
	ImageContainer,
	NextButton,
} from '../../../../components';

const CheckupContainerWrapper = styled(CheckupContainer)`
	padding-top: 32px;
`;

const AccordionContainer = styled.div``;

const StartTestButton = styled(NextButton)`
	margin-top: 15px;
`;

const SkipTestButton = styled(NextButton)`
	&& {
		margin-top: 15px;
	}
`;

const FootnoteContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;

const Footnote = styled.div`
	max-width: 250px;
	text-align: center;
	font-size: 14px;
	line-height: 16px;
`;

const TurnOffWiFiPage: FunctionComponent = () => {
	const pageId = DiagnosticsPageIds.SIGNAL_TURN_OFF_WIFI;
	const startTestButtonId = `${pageId}_${ComponentIds.START_TEST_BUTTON}`;
	const skipTestButtonId = `${pageId}_${ComponentIds.SKIP_TEST_BUTTON}`;

	const history = useHistory();
	const { usePageContent } = useCheckupContentContext();

	const {
		questionTitleCopy,
		questionImage,
		skipButtonCopy,
		nextButtonCopy: startTestButtonCopy,
		footnote,
		instructionsCopy,
	} = usePageContent(pageId);

	const onStartTestButtonClick = () =>
		history.push(CheckupDiagnosticsPathnames.SIGNAL_UPLOAD_DOWNLOAD, {
			from: history.location.pathname,
		});

	const onSkipTestButtonClick = () =>
		history.push(CheckupQuestionPathnames.SIGNAL_ISSUES, {
			from: history.location.pathname,
		});

	return (
		<CheckupContainerWrapper>
			<QuestionHeader>{questionTitleCopy}</QuestionHeader>
			<ImageContainer>
				<img src={questionImage.fields.file.url} alt="" />
			</ImageContainer>
			<AccordionContainer>
				<RichText document={instructionsCopy} />
			</AccordionContainer>
			<StartTestButton
				onClick={onStartTestButtonClick}
				actionId={startTestButtonId}
				data-cy={startTestButtonId}
				analyticExtras={{
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{startTestButtonCopy}
			</StartTestButton>
			<SkipTestButton
				variant="outline"
				onClick={onSkipTestButtonClick}
				actionId={skipTestButtonId}
				data-cy={'skipButton'}
				analyticExtras={{
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{skipButtonCopy}
			</SkipTestButton>
			<FootnoteContainer>
				<Footnote>
					<RichText document={footnote} />
				</Footnote>
			</FootnoteContainer>
		</CheckupContainerWrapper>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.SIGNAL_TURN_OFF_WIFI,
	CheckupType: Checkups.SIGNAL,
})(TurnOffWiFiPage);
