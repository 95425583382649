import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	AnalyticViewType,
	AnalyticEventType,
	useAnalytics,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import {
	useCheckupContentContext,
	RichText,
	Article,
	useArticleContentContext,
	FeedbackChoicesTypes,
} from '@home-diy-toolbox/web/contentful';
import {
	CheckupCategoryType,
	ResultPageIds,
	CheckupDiagnosticsPathnames,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useResults, useFlow } from '../../../providers';
import {
	CheckupContainer,
	ResultPageHeader,
	SignalTestResultDial,
	SignalSummaryPositives,
	SignalSummaryNegatives,
	ArticleSuggestionsSection,
	FeedbackForm,
	NextButton,
	OtherCheckupSection,
	ResultSectionDivider,
	FeedbackComponentTypes,
	FeedbackTypes,
} from '../../../components';
import { LoadingPage } from '../../LoadingPage';

const ResultContainer = styled(CheckupContainer)`
	padding-top: 25px;
`;

const SignalSummaryContainer = styled(CheckupContainer)`
	margin-top: 30px;
`;

const CheckSignalStrengthAgainButton = styled(NextButton)`
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: ${({ theme }) => theme.checkSignalAgainButtonType.backgroundColor};
	border-color: ${({ theme }) => theme.checkSignalAgainButtonType.borderColor};
	color: ${({ theme }) => theme.checkSignalAgainButtonType.textColor};
`;

const SignalResultsPage = () => {
	const history = useHistory();
	const analytics = useAnalytics();
	const { useResultPageContent, app } = useCheckupContentContext();
	const {
		resultsCalculated,
		resultsData: { pageId, downloadSpeed, signalTestResult },
	} = useResults(Checkups.SIGNAL);
	const { getSuggestedArticles } = useArticleContentContext();
	const [articles, setArticles] = useState<Article[]>([]);

	const { downloadResultLabel, speedUnit, idealSpeedLabel } = app.signalDialConfig
		.fields || {
		downloadResultLabel: 'Current download speed',
		speedUnit: 'Mbps',
		idealSpeedLabel: 'Ideal speed: 25 Mbps',
	};

	const {
		resultPageTitle,
		signalTestSummaryPositives,
		signalTestSummaryNegatives,
		articleResultsTitle,
		actionButtonCopy,
		feedbackTitle,
		positiveFeedback,
		negativeFeedback,
	} = useResultPageContent(pageId);

	const {
		i18n: { language },
	} = useTranslation();

	const { flow, getTagsForCheckup } = useFlow();
	const {
		flowId,
		sessionData: { deviceOS },
	} = flow;

	useEffect(() => {
		const tags = getTagsForCheckup(CheckupCategoryType.SIGNAL_CHECKUP);
		getSuggestedArticles(tags, deviceOS, CheckupCategoryType.SIGNAL_CHECKUP).then(
			(foundArticles) => {
				setArticles(foundArticles);
			}
		);
	}, [language]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		analytics.dispatcher
			.extend(
				withExtras({
					PageId: ResultPageIds.SIGNAL_RESULT,
					CheckupType: Checkups.SIGNAL,
					DownloadSpeed: downloadSpeed,
					SignalTestResult: signalTestResult,
					ComponentType: AnalyticViewType.PAGE,
				})
			)
			.dispatch(AnalyticEventType.VIEW);
	}, [analytics, downloadSpeed, pageId, signalTestResult]);

	const onCheckSignalAgainClick = () => {
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'checkSignalAgain',
					CheckupType: Checkups.SIGNAL,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
		history.push(CheckupDiagnosticsPathnames.SIGNAL_UPLOAD_DOWNLOAD, {
			from: history.location.pathname,
		});
	};

	const checkSignalAgainButtonId = `checkSignalAgainButtonId`;

	if (!resultsCalculated) {
		return <LoadingPage />;
	}

	return (
		<ResultContainer data-cy={`pageId_${pageId}`} data-pw={`pageId_${pageId}`}>
			<ResultPageHeader>
				<RichText document={resultPageTitle} />
			</ResultPageHeader>
			{downloadSpeed != null && (
				<SignalTestResultDial
					downloadResultLabel={downloadResultLabel}
					speedUnit={speedUnit}
					idealSpeedLabel={idealSpeedLabel}
					signalDownloadSpeed={downloadSpeed}
					signalTestResult={signalTestResult}
				/>
			)}
			{(signalTestSummaryPositives || signalTestSummaryNegatives) && (
				<SignalSummaryContainer>
					{signalTestSummaryPositives && (
						<div data-pw="signalSummaryPositive">
							<SignalSummaryPositives
								data-cy={`signalSummaryPositive`}
								resultSummary={signalTestSummaryPositives}
							/>
						</div>
					)}
					{signalTestSummaryNegatives && (
						<div data-pw="signalSummaryNegative">
							<SignalSummaryNegatives
								data-cy={`signalSummaryNegative`}
								resultSummary={signalTestSummaryNegatives}
							/>
						</div>
					)}
				</SignalSummaryContainer>
			)}
			<ArticleSuggestionsSection
				headerCopy={articleResultsTitle}
				articleSuggestions={articles}
				checkupType={Checkups.SIGNAL}
			/>
			<CheckSignalStrengthAgainButton
				onClick={onCheckSignalAgainClick}
				data-cy={checkSignalAgainButtonId}
			>
				{actionButtonCopy || 'Check signal strength again'}
			</CheckSignalStrengthAgainButton>
			<ResultSectionDivider />
			<FeedbackForm
				variant={FeedbackComponentTypes.STARS}
				type={FeedbackTypes.SESSION}
				flowId={flowId}
				feedbackTitle={feedbackTitle}
				positiveForm={{
					...positiveFeedback.fields,
					feedbackChoicesType: FeedbackChoicesTypes.OLD,
				}}
				negativeForm={{
					...negativeFeedback.fields,
					feedbackChoicesType: FeedbackChoicesTypes.OLD,
				}}
				analyticsExtras={{ CheckupType: Checkups.SIGNAL }}
			/>
			<ResultSectionDivider />
			<OtherCheckupSection />
		</ResultContainer>
	);
};

export default SignalResultsPage;
