import styled from 'styled-components';
import { useEffect } from 'react';
import { invert } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	Checkups,
	GenericPageIds,
	SignalFilterPathnames,
	SignalPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import {
	FeedbackChoicesTypes,
	useLayoutContentContext,
} from '@home-diy-toolbox/web/contentful';
import { useTranslation } from 'react-i18next';
import { Step, useBreadcrumb, useFlow } from '../../../providers';
import { useHeader } from '../../../features/header';
import { ExpertContactCard } from '../../../components/ExpertContactCard';
import { ServiceSection } from '../../../components/CheckupServiceSection';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import { ArticleGridSection } from '../../../components/ArticleGridSection';
import { ResultSectionDivider } from '../../../components/atoms';
import {
	FeedbackComponentTypes,
	FeedbackTypes,
	FeedbackForm,
} from '../../../components/FeedbackForm';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 0 1rem;
	gap: 2.25rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		padding: 0 1.5rem;
	}

	${breakpoints.lg} {
		max-width: 75rem;
		padding: 0;
		gap: 4.125rem;
	}

	${breakpoints.xxl} {
		padding: 0 1rem;
		max-width: 90rem;
	}

	& .sectionDivider {
		margin: 1rem 0;
	}
`;

export const SignalLandingPage = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		signalPage: {
			pageTitle,
			serviceListTitle,
			serviceList,
			articleGridSection,
			feedbackSectionTitle,
			positiveFeedbackForm,
			negativeFeedbackForm,
		},
	} = useLayoutContentContext();
	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();
	const { updateHeader } = useHeader();
	const {
		flow: { flowId, sessionData },
	} = useFlow();

	useEffect(() => {
		updateHeader(
			{
				title: t(pageTitle),
			},
			'/'
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageTitle, t]);

	useEffect(() => {
		if (pageTitle) {
			const breadcrumbSteps: Step[] = [
				{
					path: '/',
					label: 'Device Care',
				},
				{
					label: pageTitle,
				},
			];
			setBreadcrumbSteps(breadcrumbSteps);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageTitle]);

	return (
		<Container className="widecontainer">
			<ArticleGridSection
				deviceOS={sessionData.deviceOS}
				articleGridSection={articleGridSection}
			/>
			<ExpertContactCard from={GenericPageIds.SIGNAL_PAGE} />
			<div>
				<ResultSectionDivider className="sectionDivider" />
				<FeedbackForm
					variant={FeedbackComponentTypes.STARS}
					type={FeedbackTypes.SESSION}
					feedbackTitle={feedbackSectionTitle}
					positiveForm={{
						...positiveFeedbackForm,
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
					}}
					negativeForm={{
						...negativeFeedbackForm,
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
					}}
					flowId={flowId}
					analyticsExtras={{ CheckupType: Checkups.SIGNAL }}
				/>
				<ResultSectionDivider className="sectionDivider" />
			</div>
			<ServiceSection sectionTitle={serviceListTitle} services={serviceList} />
		</Container>
	);
};

const pathname = window.location.pathname;
const subpath = pathname.match(/\/device-care(\/.+)/);
const filter = subpath && subpath.length > 1 ? subpath[1] : '/signal-popular';
const signalPathMap = invert(SignalFilterPathnames);
const page = signalPathMap[filter];

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: SignalPageIds[page],
	CheckupType: Checkups.SIGNAL,
})(SignalLandingPage);
