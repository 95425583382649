import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { Entry } from 'contentful';
import { useCheckupContentContext, AnswerOption } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../providers';
import { Section } from './components/section';
import StoreFixReplace from './assets/mini-store-fix-replace-smartphone.svg';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react';
import WorkFromHome from './assets/mini-work-from-home.svg';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 343px;

	${breakpoints.lg} {
		max-width: calc(100vw - 3em);
	}
`;

const Header = styled.div`
	display: flex;
	margin: 0.5rem 0px 1.5rem 0px;
	align-self: baseline;
`;

export const HomePage = (): JSX.Element => {
	const { usePageContent } = useCheckupContentContext();
	const { answerOptions } = usePageContent(GenericPageIds.IN_STORE_HOME);
	const { t } = useTranslation();
	const {
		flow: {
			sessionData: { eligibility },
		},
	} = useFlow();

	const services: Entry<AnswerOption>[] = answerOptions.filter(({ fields }) => {
		const isEligibilityRequired = Object.keys(eligibility).includes(fields.id);
		const isEligible = !!eligibility[fields.id];
		return isEligibilityRequired ? isEligible : true;
	});

	return (
		<Container className="widecontainer">
			<Header>
				<Text weight="feather" size={5} data-cy="header" as="h1">
					{t('HomePage.Title')}
				</Text>
			</Header>
			<Section
				title={t('Services.Digital.Title')}
				description={t('Services.Digital.Description')}
				visual={WorkFromHome}
				services={services.slice(0, 3)}
			/>
			<Section
				title={t('Services.InStores.Title')}
				description={t('Services.InStores.Description')}
				visual={StoreFixReplace}
				services={services.slice(3, 6)}
			/>
		</Container>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: GenericPageIds.IN_STORE_HOME,
	CheckupType: undefined,
})(HomePage);
